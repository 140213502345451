import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// config
import { FIREBASE_API, FCM_KEY } from 'src/config-global';
//
import userService from 'src/services/user-service';
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);
AUTH.settings.appVerificationDisabledForTesting = false;

export const DB = getFirestore(firebaseApp);

export const MESSAGING = getMessaging(firebaseApp);

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        console.log('onAuthStateChanged', user);
        let profile = {
          displayName: null,
          email: null,
        };

        if (user) {
          const resp = await userService.getUser(user.uid);
          console.log('response', resp);
          if (resp.displayName && resp.email) {
            profile = resp;
          }

          dispatch({
            type: 'INITIAL',
            payload: {
              user: {
                ...user,
                ...profile,
                id: user.uid,
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
    onMessageListener()
      .then((payload) => {
        console.log('Foreground Notification', {
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        });
      })
      .catch((err) => console.error('Failed Foreground Notification: ', err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // GET NOTIFICATION TOKEN
  const getNotificationToken = useCallback(async () => {
    console.log('Get token');
    try {
      const currentToken = await getToken(MESSAGING, { vapidKey: FCM_KEY });
      if (currentToken) {
        console.log('token', currentToken);
        // Reference to the token entry document
        const tokenRef = doc(DB, `visa-fcm-tokens/${state.user.uid}`);

        // Check if token entry exists
        const docSnap = await getDoc(tokenRef);
        if (docSnap.exists()) {
          // If the document exists, check if the current token is already in the tokens array
          const data = docSnap.data();
          if (data.tokens && !data.tokens.includes(currentToken)) {
            // If the token is not in the array, add it
            await updateDoc(tokenRef, {
              tokens: arrayUnion(currentToken),
            });
          }
        } else {
          // If not, create a new token entry with the uid
          await setDoc(tokenRef, {
            uid: state.user.uid,
            tokens: [currentToken],
          });
        }
      } else {
        console.log('No fcm token available. Request permission to generate one.');
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
    }
  }, [state.user?.uid]);

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(MESSAGING, (payload) => {
        console.log('payload', payload);
        if (payload) {
          showNotification(payload);
        }
        resolve(payload);
      });
    });

  // BroadcastChannel to recieve background messaging
  const channel = new BroadcastChannel('notification_channel_name');
  channel.onmessage = (event) => {
    showNotification(event.data.key);
  };
  const showNotification = (body) => {
    if (body) {
      // notification sound
      // const sound = new Audio(n5);
      // sound.play();
      // notification body
      const options = {
        body: body.notification.body,
        icon: 'icon.png',
        vibrate: true, // vibrate will work only on the mobile device
      };
      const notification = new Notification(body.notification.title, options);
      notification.onclick = function (event) {
        event.preventDefault();
        window.open('https://google.com', '_blank');
      };
    }
  };
  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(AUTH, 'recaptcha-container', {
        size: 'invisible', // Use 'invisible' to automatically verify, or 'normal' for visible
        callback: (response) => {
          console.log('reCAPTCHA resolved with response:', response);
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired, please solve it again');
        },
      });
    }
  };

  const loginWithPhoneNumber = useCallback(async (phoneNumber, setError, setLoading) => {
    setUpRecaptcha();

    const appVerifier = window.recaptchaVerifier;
    console.log('appVerifier', appVerifier);
    setLoading(true);

    try {
      const confirmationResult = await signInWithPhoneNumber(AUTH, phoneNumber, appVerifier);
      console.log('confirmationResult', confirmationResult);
      window.confirmationResult = confirmationResult;
      console.log('SMS sent successfully');
      setLoading(false);
    } catch (error) {
      console.error('Error during sign-in with phone number', error);
      setError('Failed to send OTP. Please try again.');
      setLoading(false);
      throw error;
    }
  }, []);

  const register = useCallback(async (email, password, firstName, lastName) => {
    const newUser = await createUserWithEmailAndPassword(AUTH, email, password);

    await sendEmailVerification(newUser.user);

    const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);

    await setDoc(userProfile, {
      uid: newUser.user?.uid,
      email,
      displayName: `${firstName} ${lastName}`,
    });

    const docSnap = await getDoc(userProfile);
    const profile = docSnap.data();

    console.log('profile', profile);

    dispatch({
      type: 'INITIAL',
      payload: {
        user: {
          ...newUser.user,
          ...profile,
          id: newUser.user.uid,
        },
      },
    });
  }, []);

  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  const forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      getNotificationToken,
      loginWithPhoneNumber,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
      getNotificationToken,
      loginWithPhoneNumber,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
