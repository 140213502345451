import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AppBar, Toolbar } from '@mui/material';

// auth
import { useAuthContext } from 'src/auth/hooks';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import { pxToRem } from 'src/theme/typography';

// ----------------------------------------------------------------------

const METHODS = [
  {
    id: 'jwt',
    label: 'Jwt',
    path: paths.auth.jwt.login,
    icon: '/assets/icons/auth/ic_jwt.svg',
  },
  {
    id: 'firebase',
    label: 'Firebase',
    path: paths.auth.firebase.login,
    icon: '/assets/icons/auth/ic_firebase.svg',
  },
  {
    id: 'amplify',
    label: 'Amplify',
    path: paths.auth.amplify.login,
    icon: '/assets/icons/auth/ic_amplify.svg',
  },
  {
    id: 'auth0',
    label: 'Auth0',
    path: paths.auth.auth0.login,
    icon: '/assets/icons/auth/ic_auth0.svg',
  },
];

export default function AuthClassicLayout({ children, image, title }) {
  const { method } = useAuthContext();

  const theme = useTheme();

  const upMd = useResponsive('up', 'md');

  const renderHeader = (
    <AppBar
      position="fixed" // Fixes the header to the top
      sx={{
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',
      }}
    >
      <Toolbar>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Logo />
        </Box>
      </Toolbar>
    </AppBar>
  );

  const renderContent = (
    <Stack
      flexGrow={1}
      sx={{
        width: 1,
        maxWidth: '100%',
        px: { xs: 2, md: 0 },
        py: { xs: 15, md: 35 },
        mx: { xs: 'auto', md: 15, lg: 25 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
        }),
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          component="img"
          alt="auth"
          src={image || '/assets/illustrations/login.png'}
          sx={{
            width: { xs: pxToRem(1000), md: pxToRem(1500), lg: pxToRem(3000) },
            height: '100%',
            objectFit: 'cover',
            // filter: 'brightness(0.7)',
          }}
        />

        <Typography
          variant="h3"
          align="center"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Center text
            color: 'white', // Adjust color based on your design
            fontWeight: 'bold', // Optional styling
            margin: 0, // Remove any margins
            padding: 0, // Remove padding if any
            width: '100%', // Ensure the text takes the full width
          }}
        >
          Complete your travel checklist <br /> with on-time visa.
        </Typography>
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderHeader}

      {upMd && renderSection}

      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
