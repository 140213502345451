import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const Page404 = lazy(() => import('src/pages/404'));
const PrivacyPolicyPage = lazy(() => import('src/pages/privacy-policy'));
const TermsAndConditionsPage = lazy(() => import('src/pages/terms-and-conditions'));
// COUNTRY
const CountryDetailsPage = lazy(() => import('src/pages/country/details'));
// BLOG
const BlogPage = lazy(() => import('src/pages/blogs/view/list'));
const BlogDetailsPage = lazy(() => import('src/pages/blogs/view/details'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      {
        path: 'apply-visa',
        children: [
          { element: <Page404 />, index: true },
          { path: ':code', element: <CountryDetailsPage /> },
        ],
      },
      {
        path: 'blog',
        children: [
          { element: <BlogPage />, index: true },
          { path: ':code', element: <BlogDetailsPage /> },
        ],
      },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
      { path: 'terms-and-conditions', element: <TermsAndConditionsPage /> },
      { path: '404', element: <Page404 /> },
    ],
  },
];
