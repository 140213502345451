import axios from 'axios';
import { AUTH } from 'src/auth/context/firebase/auth-provider';
// config
import { VISA_BACKEND_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: VISA_BACKEND_API });

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const { currentUser } = AUTH;
    if (currentUser) {
      const token = await currentUser.getIdToken();
      config.headers.authtoken = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  order: {
    root: '/order',
    new: '/order/new',
    heartbeat: (_id) => `/order/heartbeat/${_id}`,
    details: (_id) => `/order/details/${_id}`,
    verify: (_id) => `/order/verify/${_id}`,
    list: '/order/list',
    update: (_id) => `/order/update/${_id}`,
  },
  faqs: {
    details: (_id) => `/faqs/${_id}`,
  },
  user: {
    root: '/user',
    new: '/user/new',
    getUser: (uid) => `/user/get/${uid}`,
  },
  whatsapp: {
    test: '/misc/checkWhatsappNumber',
  },
};
