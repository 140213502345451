import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Button, Link, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify';

import { whatsappLink } from 'src/config-global';
//
import { paths } from 'src/routes/paths';
import { pxToRem } from 'src/theme/typography';
import { useAuthContext } from 'src/auth/hooks';
import { RouterLink } from 'src/routes/components';
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  ContactsPopover,
  NotificationsPopover,
} from '../_common';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const { authenticated } = useAuthContext();

  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');
  const smUp = useResponsive('up', 'sm');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const location = useLocation();
  const isOrderPage = location.pathname === '/dashboard/order/list';
  const renderContent = (
    <>
      {/* {lgUp && isNavHorizontal &&  } */}
      <Logo sx={{ mr: 2.5, width: { xs: 100, sm: 'unset' } }} />

      {/* {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 1, sm: 1, md: 3 }}
      >
        {/* <LanguagePopover />

        <NotificationsPopover />

        <ContactsPopover />

        <SettingsButton /> */}
        <Link
          component={RouterLink}
          sx={{ py: 1 }}
          typography="body2"
          href={paths.blog.root}
          color="text.primary"
        >
          Blog
        </Link>
        <IconButton size="large" href={whatsappLink} target="_blank" color="primary">
          <Iconify icon="iconoir:headset-help" />
        </IconButton>

        {authenticated && <AccountPopover />}
        {!smUp && !authenticated && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            href={paths.auth.firebase.login}
          >
            Sign In
          </Button>
        )}
        {smUp && !isOrderPage && (
          <Button
            variant={authenticated ? 'outlined' : 'contained'}
            sx={{ fontSize: { xs: pxToRem(8), md: pxToRem(15) }, fontWeight: 'bold' }}
            href={authenticated ? paths.dashboard.order.list : paths.auth.firebase.login}
          >
            {authenticated ? 'My Applications' : 'Sign In'}
          </Button>
        )}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: 1,
          // `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: 1,
            // `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
