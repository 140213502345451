import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const CountryContext = createContext({});

export const useCountryContext = () => {
  const context = useContext(CountryContext);

  if (!context) throw new Error('useCountryContext must be use inside CountryProvider');

  return context;
};
