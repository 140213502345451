import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import { useEffect, useMemo, useCallback } from 'react';
// hooks
import { useLocalStorage, getStorage } from 'src/hooks/use-local-storage';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// _mock
import {
  COUNTRY_CHECKOUT_STEPS,
  DATE_CLARITY_TYPES,
  DEFAULT_DEPARTURE_COUNTRY,
} from 'src/_mock/_country';
//
import { CountryContext } from './country-context';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'country';

const initialState = {
  activeStep: 0,
  country: null,
  total: 0,
  quantity: 1,
  date: new Date(),
  passportPhotoIndex: 0,
  billing: null,
  shipping: null,
  coTravellers: [],
  frontDecodedValues: {},
  cameraSource: 'user',
  departureFrom: DEFAULT_DEPARTURE_COUNTRY,
  dateClarity: DATE_CLARITY_TYPES[0],
};

export function CountryProvider({ children }) {
  const router = useRouter();

  const { state, update, reset } = useLocalStorage(STORAGE_KEY, initialState);

  const onGetCart = useCallback(() => {
    // const totalItems = state.items.reduce((total, item) => total + item.quantity, 0);
    // const subTotal = state.items.reduce((total, item) => total + item.quantity * item.price, 0);
    // update('subTotal', subTotal);
    // update('totalItems', totalItems);
    // update('billing', state.activeStep === 1 ? null : state.billing);
    // update('discount', state.items.length ? state.discount : 0);
    // update('shipping', state.items.length ? state.shipping : 0);
    // update('total', state.subTotal - state.discount + state.shipping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.items,
    state.activeStep,
    state.billing,
    state.discount,
    state.shipping,
    state.subTotal,
  ]);

  // useEffect(() => {
  //   const restored = getStorage(STORAGE_KEY);

  //   if (restored) {
  //     onGetCart();
  //   }
  // }, [onGetCart]);

  useEffect(() => {
    const restored = getStorage(STORAGE_KEY);

    if (restored) {
      onGetCart();
    }
  }, [onGetCart]);

  const onAddToCart = useCallback(
    (payload) => {
      update('country', payload.country);
      update('price', payload.price);
      update('quantity', payload.quantity);
      update('total', payload.total);
      update('activeStep', 0);
    },
    [update]
  );

  const onDeleteCart = useCallback(() => {
    update('country', null);
    update('price', null);
    update('quantity', null);
    update('total', null);
  }, [update]);

  const onBackStep = useCallback(() => {
    if (state.activeStep === 0) {
      router.back();
      return;
    }
    update('activeStep', state.activeStep - 1);
  }, [update, state.activeStep, router]);

  const onNextStep = useCallback(() => {
    update('activeStep', state.activeStep + 1);
  }, [update, state.activeStep]);

  const onGotoStep = useCallback(
    (step) => {
      update('activeStep', step);
    },
    [update]
  );

  const setPassportPhotoIndex = useCallback(
    (index) => {
      update('passportPhotoIndex', index);
    },
    [update]
  );

  const onCreateBilling = useCallback(
    (address) => {
      update('billing', address);

      onNextStep();
    },
    [onNextStep, update]
  );

  const onToggleCameraSource = useCallback(() => {
    update('cameraSource', state.cameraSource === 'user' ? { exact: 'environment' } : 'user');
  }, [update, state.cameraSource]);

  const onAddPhoto = useCallback(
    (url) => {
      update('photo', url);
    },
    [update]
  );

  const onAddPassportPhotos = useCallback(
    (urlsArray) => {
      update('passportPhotos', urlsArray);
    },
    [update]
  );

  const onAddPassportDetails = useCallback(
    (values) => {
      update('passportDetails', values);
      // console.log('values', values)
    },
    [update]
  );
  console.log('STATE', state);
  const onAddCoTraveler = useCallback(
    (val) => {
      console.log('in on add co traveller');
      console.log('state inside', state);
      console.log('state.cotraveller', state.coTravellers);
      const coTravellers = state.coTravellers ?? [];
      coTravellers.push(val);
      update('coTravellers', coTravellers);
      update('quantity', coTravellers.length + 1);
    },
    [update, state]
  );
  const onFrontDecodedValues = useCallback(
    (val) => {
      update('frontDecodedValues', val);
    },
    [update]
  );
  const onUpdateQuantity = useCallback(() => {
    console.log('in on add co traveller');
    console.log('state inside', state);
    console.log('state.cotraveller', state.coTravellers);
    const coTravellers = state.coTravellers ?? [];
    update('coTravellers', coTravellers);
    update('quantity', coTravellers.length + 1);
  }, [update, state]);
  const onDeleteCoTraveler = useCallback(
    (index) => {
      const tempArr = (state.coTravellers ?? []).filter((val, ind) => ind !== index);
      state.coTravellers = tempArr;
      update('coTravellers', tempArr);
    },
    [update, state]
  );
  const onEditCoTraveler = useCallback(
    (value, index) => {
      const tempArr = (state.coTravellers ?? []).map((val, ind) => {
        if (ind === index) {
          return value;
        }
        return val;
      });
      state.coTravellers = [...tempArr];
      update('coTravellers', tempArr);
    },
    [update, state]
  );
  const onApplyDate = useCallback(
    (date) => {
      update('date', date);
    },
    [update]
  );

  const onApplyDepartureFrom = useCallback(
    (departureFrom) => {
      update('departureFrom', departureFrom);
    },
    [update]
  );

  const onApplyDateClarity = useCallback(
    (dateClarity) => {
      update('dateClarity', dateClarity);
    },
    [update]
  );

  const onApplyShipping = useCallback(
    (shipping) => {
      update('shipping', shipping);
    },
    [update]
  );

  const onSaveAddress = useCallback(
    (address) => {
      update('address', address);
    },
    [update]
  );

  const goToPayment = useCallback(() => {
    const totalPax = (state.coTravellers?.length ?? 0) + 1;
    update('subTotal', totalPax * (state.price ?? 0));
    update('total', totalPax * (state.price ?? 0) + (state.shipping ?? 0) - (state.discount ?? 0));
    update('activeStep', 4);
  }, [update, state.coTravellers, state.price, state.shipping, state.discount]);

  const completed = state.activeStep === COUNTRY_CHECKOUT_STEPS.length;

  // Reset
  const onReset = useCallback(() => {
    reset();
    router.replace('/');
  }, [reset, router]);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      completed,
      //
      onAddToCart,
      onDeleteCart,
      //
      onCreateBilling,
      onApplyShipping,
      onApplyDate,
      onApplyDepartureFrom,
      onApplyDateClarity,
      onToggleCameraSource,
      onAddPhoto,
      setPassportPhotoIndex,
      onAddPassportPhotos,
      onAddPassportDetails,
      onAddCoTraveler,
      onUpdateQuantity,
      onDeleteCoTraveler,
      onEditCoTraveler,
      onSaveAddress,
      onFrontDecodedValues,
      //
      onBackStep,
      onNextStep,
      onGotoStep,
      goToPayment,
      //
      onReset,
    }),
    [
      completed,
      onAddToCart,
      onBackStep,
      onCreateBilling,
      onApplyShipping,
      onApplyDate,
      onApplyDepartureFrom,
      onApplyDateClarity,
      onToggleCameraSource,
      onAddPhoto,
      setPassportPhotoIndex,
      onAddPassportPhotos,
      onAddPassportDetails,
      onAddCoTraveler,
      onUpdateQuantity,
      onFrontDecodedValues,
      onSaveAddress,
      onDeleteCart,
      onGotoStep,
      onNextStep,
      goToPayment,
      onReset,
      state,
      onDeleteCoTraveler,
      onEditCoTraveler,
    ]
  );

  return <CountryContext.Provider value={memoizedValue}>{children}</CountryContext.Provider>;
}

CountryProvider.propTypes = {
  children: PropTypes.node,
};
